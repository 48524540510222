// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calc-jsx": () => import("./../../../src/pages/calc.jsx" /* webpackChunkName: "component---src-pages-calc-jsx" */),
  "component---src-pages-foto-jsx": () => import("./../../../src/pages/foto.jsx" /* webpackChunkName: "component---src-pages-foto-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

